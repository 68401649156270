@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-popup-header {
    background-color: $ws-white;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    &--overflow {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background-color: transparent;
    }

    &--is-top {
        border-bottom: 1px solid $ngr-meny-white;
    }

    &--is-going-down {
        border-bottom: 1px solid $ngr-meny-support01-light-2;
    }

    &--is-header-image {
        border-bottom: 0;
        padding: 0;
        margin-bottom: 16px
    }

    .app-popup__wrapper--force-fullscreen & {
        padding: 0;
        height: 0;
        border-bottom: 0;
    }


    &__title {
        @include ngr-font-size(golf);

        font-weight: 700;
        display: block;
        white-space: nowrap;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0;
        transition: opacity ease-in .3s;

        &--is-top {
            opacity: 0;
        }

        &--is-going-down {
            opacity: 1;
        }
    }

    &__close-button {
        display: flex;
        width: 36px;
        min-width: 36px;
        height: 36px;
        justify-content: center;
        align-items: center;
        border-radius: $ngr-border-radius;
        background-color: $ngr-meny-support01-light-1;

        .app-popup__wrapper--force-fullscreen & {
            margin-right: 16px;
        }
    }
}