@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.container {
    @include ngr-font-size-body(14);

    padding: 0 16px 58px;
    display: flex;
    flex-direction: column;
    border-radius: $app-border-radius;
    gap: 16px;

    &--inverse {
        background-color: $ngr-color--primary;
        color: $ngr-color-white;
    }

    &--reduced-padding {
        padding: 0 16px 16px;
    }

    &--no-padding {
        padding: 0;
    }

    &--keyboard {
        padding-bottom: 41vh;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    &__title {
        @include ngr-font-size(foxtrot);

        font-weight: 700;
    }

    &__more-link {
        @include ngr-font-size(india);
    }

    &__content {
        border-radius: $app-border-radius;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &--padding {
            padding: 16px;
        }

        &--full-width {
            margin: 0 -16px;
            padding: 0;
        }

        &--background {
            &-none {
                background-color: inherit;
            }

            &-gray {
                background-color: $ngr-meny-support02-light-1;
            }
        }
    }
}