@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.welcome-box {
    &__close-button {
        position: absolute;
        top: 16px;
        right: 16px;
    }

    .app-tips__text {
        max-width: 70%;
        font-weight: bold;
    }
}