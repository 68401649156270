@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.barcode-button {
    display: block;
    color: $ngr-button-color-background--primary;

    .ngr-icon {
        display: inline-block;
        width: 28px;
        vertical-align: middle;
    }

    &--with-text {
        padding: .5em 1em;
        margin: .5rem auto;

        .ngr-icon {
            vertical-align: middle;
            margin-right: .5em;
        }
    }
}
