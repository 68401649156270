@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
* {
    outline: none;
    border: 0;
    box-shadow: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba($ngr-meny-white, 0) !important;
    margin: 0;
    list-style: none;
    padding: 0;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    background-color: transparent;
    color: inherit;
    line-height: inherit;
    touch-action: manipulation;
    font-style: normal;
}

html {
    height: 100%;
    background-color: $ngr-meny-white;
    touch-action: manipulation;
    overflow: hidden;
    font-family: $ws-font-body;
    color: $ngr-color-foreground--primary;
    font-size: 100%;
    font-weight: 400;
}

body {
    height: 100%;
    line-height: 1.5;
    overflow: hidden;

    &.has-popup {
        /* stylelint-disable-next-line selector-max-compound-selectors */
        .app-layout-main,
        *:not(.app-popup--partial) > .app-popup__wrapper > .grid > .box,
        .app-infinite-scroller,
        .show-scroll,
        .app-scroll-view {
            overflow: hidden;
        }
    }
}

strong {
    font-weight: bold;
}

a,
button,
label:not([for]),
select {
    cursor: pointer;
}

input,
select {
    color: inherit;
    font-family: inherit;
    line-height: inherit;
    font-weight: inherit;
    appearance: none;
}

@include ws-media ("screen and (max-width: 320px)") {
    textarea,
    input[type="text"],
    input:not([type]) {
        font-size: 1rem !important;
    }
}

a {
    text-decoration: none;
}

input[type="checkbox"] {
    appearance: checkbox;
}

input[type="radio"] {
    appearance: radio;
}

fieldset {
    min-width: 0;
}

legend {
    display: table;
    float: left;
    margin: 0;
    padding: 0;
    width: 100%;

    +* {
        clear: both;
    }
}

*:not(input, textarea) {
    // this disables input fields on touch!
    user-select: none !important;
    -webkit-touch-callout: none !important;
}

::-webkit-search-cancel-button {
    display: none;
}

::placeholder {
    color: rgba($ws-black, .25);
}

code {
    @include ngr-font-size(kilo);

    border: 1px solid $ngr-meny-support01-light-3;
    background-color: $app-faint-border-color;
    color: rgba($ws-black, .5);
    border-radius: $ws-border-radius;
    font-family: monospace;
    padding: 6px 8px;
    margin-right: 2px;
}

video {
    max-width: 100%;
    height: auto;
}

fieldset {
    outline: none;
    border: 0;
    margin: 0;
    padding: 0;
}

// Accessibility

:focus-visible {
    outline: 2px solid $ngr-color-focus;
}

input.ngr-checkbox__input:focus-visible + .ngr-checkbox__label {
    outline: 2px solid $ngr-color-focus;
}
