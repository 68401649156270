@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.icon-button {
    background-color: $ngr-button-color-background--primary;
    border-radius: 50%;
    padding: 9px;
    color: $ngr-meny-white;

    &--secondary {
        background-color: $ngr-button-color-background--secondary;
        color: $ngr-meny-black;
    }
}
