@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
$snow-base-speed: 2.5s;

.christmas-campaign {
    background-color: $ngr-meny-support07-dark-3;
    color: $ngr-meny-white;
    padding: 2rem 1rem;
    text-align: center;
    margin-bottom: 2rem;
    min-height: 374px;
    // snow stuff
    // overflow: hidden; // overflow hidden causes issues in Safari
    position: relative;
    z-index: 5; // raise slightly because Safari stacking context is weird

    &__wave {
        display: block;
        margin: 1rem auto;
        width: 32%;
    }

    &__crystal1,
    &__crystal2,
    &__crystal3,
    &__crystal4 {
        color: $ngr-meny-support07-dark-2;

        position: absolute;
        top: 50%;
        margin-top: -32px;
        width: 64px;
        height: 25%;
        z-index: 5;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__crystal1 {
        right: 30%;
        width: 32px; // smallest
    }

    &__crystal2 {
        left: 10%;
        width: 48px; // medium
    }

    &__crystal3 {
        left: 30%;
    }

    &__crystal4 {
        right: 10%;
    }

    &__content {
        position: relative;
        z-index: 6; // raise above crystals
    }


    &__logo {
        display: block;
        margin: 1rem auto;
        max-width: 100%;
        height: auto;
        text-align: center;
    }

    &__title {
        @include ngr-font-size(hotel);

        font-weight: bold;
    }

    &__text {
        margin: 1rem;
    }

    &__ruleslink {
        margin: 1rem;
    }

    .ngr-button--inline-link,
    .ngr-button--inline-block-link,
    .ngr-button--plain-link {
        color: $ngr-meny-white;

        &:active,
        &:hover {
            color: $ngr-meny-white !important;
        }

        .ngr-button__icon {
            color: inherit;
        }
    }

    .ngr-close-button.consent-act__close-button {
        display: none;
    }

    .consent-act__item--xmas {
        background: $ngr-meny-white;
    }

    &__prize-link-button,
    &__prizeslink.ngr-button--inline-block-link,
    .lottery__triggerbutton,
    .ws-login-button {
        background-color: $ngr-meny-support07-dark-3;
        color: $ngr-meny-white;
        border: 1px solid $ngr-meny-white;

        &:focus,
        &:active,
        &:hover {
            background-color: $ngr-meny-white !important;
            color: $ngr-meny-support07-dark-3 !important;
            border: 1px solid $ngr-meny-white !important;
        }

        .ngr-button__icon {
            color: inherit;
        }
    }

    &__prizeslink.ngr-button--inline-block-link {
        padding: 11px 16px;
    }


    .lottery__triggerbutton {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    // Shame

    .ws-login-button {
        .ngr-button__icon {
            display: none;
        }
    }
}

@media (prefers-reduced-motion: reduce) {
    .christmas-campaign {
        // "Randomly" place crystals so they dont all line up
        &__crystal1 {
            top: 20%;
        }

        &__crystal2 {
            top: 70%;
        }

        &__crystal3 {
            top: 15%;
            left: 20%;
        }

        &__crystal4 {
            top: 50%;
        }
    }
}

@media (prefers-reduced-motion: no-preference) {
    // Only animate crystals if not reduced motion
    .christmas-campaign {
        &__crystal1,
        &__crystal2,
        &__crystal3,
        &__crystal4 {
            transform: translate3d(0, -250%, 0);
            opacity: 0; // hide initially
            animation-duration: 6 * $snow-base-speed;
            animation-name: fallingsnow;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }

        &__crystal1 {
            // smallest, so it's furthest away and has appears to fall slowest
            animation-duration: 7.3 * $snow-base-speed;
        }

        &__crystal2 {
            // medium, so it's medium distance and speed
            animation-duration: 6 * $snow-base-speed;
            animation-delay: 0.4 * $snow-base-speed;
        }

        &__crystal3 {
            // These are big, so they are closer and appear to fall faster
            animation-duration: 4.9 * $snow-base-speed;
            animation-delay: 1 * $snow-base-speed;
        }

        &__crystal4 {
            animation-duration: 4.1 * $snow-base-speed;
            animation-delay: 0.1 * $snow-base-speed;
        }
    }

    @keyframes fallingsnow {
        from {
            transform: translate3d(0, -250%, 0);
            opacity: 0; // fade in and out at the top and bottom since we cannot use overflow hidden
        }

        25% {
            transform: translate3d(-16%, -125%, 0);
            opacity: 0.3;
        }

        50% {
            transform: translate3d(6%, 0%, 0);
            opacity: 1;
        }

        75% {
            transform: translate3d(24%, 125%, 0);
            opacity: 0.3;
        }

        to {
            transform: translate3d(10%, 250%, 0);
            opacity: 0;
        }
    }

}
