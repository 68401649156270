@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-scroll-view {
    height: 100%;
    display: block;
    width: 100vw;
    position: relative;
    overflow: hidden;
    background-color: $ngr-meny-white;

    &--transparent {
        background-color: transparent;
    }

    &__inner {
        height: 100%;
        width: 100%;
        display: block;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        will-change: scroll-position;
        backface-visibility: hidden;

        &--scrollbar {
            padding-right: 0;
        }
    }
}
