@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.welcome-popup {
    &__image {
        width: 100%;
        height: 260px;
        object-fit: cover;
        margin-bottom: 32px;
    }

    &__login-button {
        width: 100%;
    }

    &__login-content {
        margin-top: 32px;
    }
}