@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.horizontal-scroller {
    overflow: hidden;
    width: 100%;
    box-sizing: content-box;
    margin-bottom: -10px; // hide scrollbar

    &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        flex-direction: row;
        box-sizing: content-box;
        overflow-x: scroll;
        padding-bottom: 10px; // hide scrollbar

        &::after {
            flex: none;
            display: block;
            content: "";
            width: $ws-container-horizontal-gutter;
        }

        &::before {
            flex: none;
            display: block;
            content: "";
            width: $ws-container-horizontal-gutter;
        }
    }

    &__element {
        flex: none;
        width: 80%;
        margin-right: 24px;
        border-radius: $app-border-radius;
        overflow: hidden;
        background-clip: content-box;

        &--small {
            width: 281px;
            margin-right: 16px;
        }

        &--single {
            width: calc(100% - (2 * $ws-container-horizontal-gutter));
        }

        .app-product {
            border-radius: 3px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}