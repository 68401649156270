@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.ultra-compact-weekmenu {
    min-width: 100%;
    margin: 0 auto;

    .ngr-tabs__tab {
        border-width: 0;
        border-radius: 0;

        &__icon {
            margin: 0;
        }

        /* stylelint-disable-next-line selector-class-pattern */
        &--icon .ngr-tabs__tab__label {
            display: none;
        }

        &__label {
            line-height: 24px;
            min-height: 24px;
        }
    }

    .ngr-tabs--horizontal {
        gap: 0;
        box-shadow: none;

        &::after {
            display: none;
        }
    }

    &__texts {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 8px;

        .c-h5 {
            font-size: 1.125em;
            font-weight: 700;
            padding: 0;
            margin: 0;
        }
    }

    &__card {
        background-color: $ngr-meny-support02-light-1;
        border-radius: $app-border-radius;
        // padding: 20px;
        display: flex;
        flex-direction: row;
        gap: 8px;

        img {
            object-fit: cover;
            border-radius: $app-border-radius 0 0 $app-border-radius;
        }
    }

    &__rating.recipe-rating {
        justify-content: flex-start;

        svg {
            width: 13px !important;
            height: 13px !important;
        }
    }

    &__recipeimages {
        position: relative;
        height: 114px;
    }

    &__tabwrapper {
        container-type: inline-size;
        height: auto;
        margin-bottom: 1rem;
        margin-inline: 16px;
    }

    &__tabs,
    &__tiny-tabs {
        width: 100%;
        background: transparent;

        .ngr-tabs__tab {
            padding: 8px 0;

            &:hover {
                text-decoration: none;
            }

            &--selected {
                color: $ws-text-color;
            }

            &:focus-visible {
                outline-offset: -2px;
            }

        }
    }

    .swiper-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}


// Fallback for old browsers that doesn't support container-queries
.ngr-tabs.ultra-compact-weekmenu__tiny-tabs {
    display: none;
}

@container not (width > 20rem) {
    .ngr-tabs.ultra-compact-weekmenu__tabs {
        display: none;
    }

    .ngr-tabs.ultra-compact-weekmenu__tiny-tabs {
        display: flex;
    }
}

@container (width > 20rem) {
    .ngr-tabs.ultra-compact-weekmenu__tabs {
        display: flex;
    }
}
