@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.error-boundary {
    position: fixed;
    inset: 0;
    background-color: $ws-dark-bg-color;
    color: $ws-white;
    z-index: 1000;
    padding: 10vh 2rem;

    &__title {
        font-size: 45px;
        line-height: 51px;
        font-family: $ws-font-heading;
        font-weight: bold;
        margin-bottom: 5px;
    }

    &__text {
        font-size: 16px;
        margin-bottom: 35px;
    }

    &__sub-button {
        opacity: .15;
        display: block;
        position: fixed;
        bottom: 10px;
        right: 15px;
        padding: 16px;
        text-transform: uppercase;
        font-size: 12px;
    }

    &__deets {
        width: 100vw;
        height: 100vh;
        padding: 16px;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
    }
}