@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-layout-wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: $ngr-meny-white;
    transition:
        transform $app-animation-duration ease-out,
        border-radius $app-animation-duration ease-out;
    /* stylelint-disable-next-line length-zero-no-unit */
    border-radius: 0px; // Needs unit for animation to work
    transform-origin: top center;

    &--full-screen-popup {
        transform: scale(.91) translateY(max(var(--safe-area-top), 9px));
        border-radius: 10px;
    }
}
