@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.lottery {
    // Fullscreen popup:
    text-align: center;

    &__extra-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 100;
        font-size: .875em;
        padding: .75em;
        width: 36px;
        min-width: 36px;
        height: 36px;
        border-radius: $ngr-border-radius;
        background-color: $ngr-meny-support01-light-1;
    }

    &__debug_buttons {
        position: absolute;
        top: 2.6rem;
        right: 4rem;
        z-index: 100;
        font-size: .875em;
    }

    &__inner {
        position: relative;
        height: 100%;
        width: 100%;
    }

    &__triggerimage {
        max-width: 600px;
        margin: 0 auto;
        display: block;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__framewrap {
        position: fixed;
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;
        opacity: 0;
        overflow: hidden;
        background-color: $ngr-meny-support01-dark-1;
        transition: all 400ms ease;
        z-index: 0;
        transition-delay: 10ms;

        .ngr-spinner {
            position: absolute;
            top: 40%;
            left: 0;
            right: 0;
            margin: 0 auto;
            display: none;
        }

        iframe {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 200ms;
            transition-delay: 10ms;
            max-width: 75vh; // avoid scrolling on iPad landscape orientation
            margin: 0 auto;
        }

        &--is-open {
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            opacity: 1;
            transition-delay: 20ms;
            z-index: 99;

            .ngr-spinner {
                display: block;
            }

            iframe {
                opacity: 1;
                transition-delay: 300ms;
            }
        }
    }

    // The remaining styles here is just for the current dummy implementation:

    h2 {
        @include ngr-font-size(foxtrot);

        margin: 1rem;
    }

    h3 {
        @include ngr-font-size(hotel);

        margin: 1rem;
    }

    p {
        margin: 1rem;
    }

    &__tipslink {
        @include ngr-font-size(hotel);
    }

    code {
        display: block;
        margin: 0.5rem;
    }
}