@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.grid {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: auto;
    height: 100%;
}

.box {
    flex: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;

    &--unflex {
        flex: none;
        overflow-y: visible;
        will-change: auto;
    }
}
