@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.insight-popup {
    display: flex;
    flex-direction: column;

    &__description {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    &__accordion-content {
        padding-bottom: 24px;
    }

    &__label,
    // Shame
    .ws-diet-group-info__label {
        @include ngr-font-size(kilo);

        font-weight: bold;
        margin: 0;
    }

    .ws-diet-group-info__header,
    .ws-climate-popup__header {
        // Same as popup-title
        @include ngr-font-size(echo);
        font-weight: bold;
        margin-bottom: 24px;
    }

    .ws-climate-popup__subheader {
        // Same as popup-subtitle
        @include ngr-font-size(india);
        font-weight: bold;
        margin-bottom: 0;
    }

    .ws-climate-popup__section,
    .ws-diet-group-info {
        &,
        > p {
            // Same as popup-text
            @include ngr-font-size(india);
        }

        /* stylelint-disable-next-line selector-max-compound-selectors */
        p:not(:last-child) {
            margin-bottom: 11px;
        }

        /* stylelint-disable-next-line selector-max-compound-selectors */
        ul {
            margin: 1rem 0;

            /* stylelint-disable-next-line selector-max-compound-selectors */
            li {
                margin: 0 0 1rem 1rem;
                list-style-position: outside;
                list-style-type: disc;
            }
        }


        /* stylelint-disable-next-line selector-max-compound-selectors */
        ol {
            margin: 1rem 0;


            /* stylelint-disable-next-line selector-max-compound-selectors */
            li {
                margin: 0 0 1rem 1rem;
                list-style-position: outside;
                list-style-type: decimals;
            }
        }
    }

    .ngr-tabs {
        margin-bottom: 1rem;
    }
}
