@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.top-banner-popup {
    text-align: center;

    &__image {
        display: block;
        width: 100%;
        margin-bottom: 32px;
    }


}
