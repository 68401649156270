@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.message-after-event {
    @include ngr-font-size(india);

    text-align: center;
    padding: 1rem;
    background: $ngr-meny-white;
    border-radius: .25rem;
    margin: 1rem auto;
    box-shadow: $ngr-box-shadow;
    max-width: 50em;

    .ngr-icon--inline .ngr-icon__svg {
        margin-right: 1rem;
        vertical-align: middle;
    }
}
