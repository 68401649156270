@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-layout-top {
    background-color: $ngr-meny-white;
    position: relative;
    overflow: hidden;
    transform: translateX(0);
    display: flex;
    flex-direction: column;

    &--footerless {
        z-index: 1;
    }
}

// Page transitions

.slide-back-enter {
    transform: translateX(-20vw);

    &,
    &-active {
        position: relative;
        z-index: 999;
        width: 100vw;
    }

    &-active {
        transition: transform $app-animation-duration ease;
        transform: translateX(0);
    }
}

.slide-back-exit {
    transform: translateX(0);

    &,
    &-active {
        position: absolute;
        top: 0;
        left: 0;
        bottom: $app-footer-height;
        z-index: 1000;
        width: 100vw;
    }

    &-active {
        transition: transform $app-animation-duration ease;
        transform: translateX(100vw);
    }
}

.slide-forward-enter {
    transform: translateX(100vw);

    &,
    &-active {
        position: relative;
        z-index: 1000;
        width: 100vw;
    }

    &-active {
        transition: transform $app-animation-duration ease;
        transform: translateX(0);
    }
}

.slide-forward-exit {
    transform: translateX(0);

    &,
    &-active {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100vw;
    }

    &-active {
        transition: transform $app-animation-duration ease;
        transform: translateX(-100vw);
    }
}

.slide-back-enter {
    transform: translateX(-20vw);

    &,
    &-active {
        position: relative;
        z-index: 999;
        width: 100vw;
    }

    &-active {
        transition: transform $app-animation-duration ease;
        transform: translateX(0);
    }
}

.slide-back-exit {
    transform: translateX(0);

    &,
    &-active {
        position: absolute;
        top: 0;
        left: 0;
        bottom: $app-footer-height;
        z-index: 1000;
        width: 100vw;
        height: 100vh;
    }

    &-active {
        transition: transform $app-animation-duration ease;
        transform: translateX(100vw);
    }
}

.slide-forward-enter {
    transform: translateX(100vw);

    &,
    &-active {
        position: relative;
        z-index: 1000;
        width: 100vw;
        height: calc(var(--base-height) - var(--safe-area-bottom) - #{$ws-footer-height});
    }

    &-active {
        transition: transform $app-animation-duration ease;
        transform: translateX(0);
    }
}

.slide-forward-exit {
    transform: translateX(0);

    &,
    &-active {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100vw;
    }

    &-active {
        transition: transform $app-animation-duration ease;
        transform: translateX(-100vw);
    }
}
