@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-popup-buttons {
    padding: 12px 16px 32px;
    border-top: 1px solid $ngr-meny-support01-light-3;
    background-color: $ngr-meny-support01-light-1;
    flex: none;
    overflow-y: visible;
    will-change: auto;

    &--simple {
        border: 0;
        background-color: transparent;
    }

    &--padding {
        &-none {
            padding: 0;
        }

        &-small {
            padding: 16px;
        }

        &-medium {
            padding: 16px 16px 32px;
        }

        &-large {
            padding: 16px 24px 42px;
        }
    }

    // Shame
    .ngr-modal__button {
        margin: 0;
    }
}
