@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-home-footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 64px;
    text-align: center;

    &__illustration {
        color: $ngr-meny-primary-base;
        margin-bottom: 16px;
    }

    &__text {
        margin-bottom: 16px;
    }
}