@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.not-found {
    position: relative;
    height: 100%;
    background-image: radial-gradient(rgba($ws-white, 1), rgba($ws-white, 0));

    &__box {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 82%;
    }

    &__title {
        font-size: 30px;
        margin-bottom: 6px;
        font-weight: bold;
    }

    &__description {
        font-size: 18px;
        margin-bottom: 6px;
        opacity: .5;
    }

    &__go-home-link {
        text-align: center;
        margin-top: 32px;
    }

    &__current-url {
        position: absolute;
        bottom: 16px;
        right: 16px;
        opacity: .1;
    }
}
