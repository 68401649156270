@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.loyalty-club-info {
    &__title {
        @include ngr-font-size(foxtrot);
        font-weight: 700;
    }

    &__content {
        @include ngr-font-size(india);
    }
}