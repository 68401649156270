@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.recipe {
    &__notfound {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__image {
        height: 234px;
        position: relative;
        overflow: hidden;
        border-radius: $app-border-radius;

        &__logo {
            width: 100px;
            height: 100px;
            position: absolute;
            top: 0;
            left: 16px;
            background-color: $ngr-meny-white;
            z-index: 2;
            animation: recipe-logo-in .4s .5s both;
        }
    }
}