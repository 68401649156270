@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
@font-face {
    font-family: FuturaPassata;
    font-weight: normal;
    font-style: normal;
    src: url("/fonts/FuturaPassata.woff") format("woff"), url("/fonts/FuturaPassata.woff2") format("woff2");
    font-display: swap;
}

// Mulish font downloaded using https://gwfh.mranftl.com/fonts/mulish?subsets=latin

/* mulish-regular - latin */
@font-face {
    font-display: swap;
    font-family: Mulish;
    font-style: normal;
    font-weight: 400;
    src: url("/fonts/mulish-v12-latin-regular.woff2") format("woff2");
}

/* mulish-italic - latin */
@font-face {
    font-display: swap;
    font-family: Mulish;
    font-style: italic;
    font-weight: 400;
    src: url("/fonts/mulish-v12-latin-italic.woff2") format("woff2");
}

/* mulish-700 - latin */
@font-face {
    font-display: swap;
    font-family: Mulish;
    font-style: normal;
    font-weight: 700;
    src: url("/fonts/mulish-v12-latin-700.woff2") format("woff2");
}

/* mulish-700italic - latin */
@font-face {
    font-display: swap;
    font-family: Mulish;
    font-style: italic;
    font-weight: 700;
    src: url("/fonts/mulish-v12-latin-700italic.woff2") format("woff2");
}
