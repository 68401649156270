@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-status {
    position: absolute;
    overflow: hidden;
    bottom: 100%;
    left: 0;
    right: 0;
    animation: status-in $ws-transition-duration both;
    z-index: 10;
}

@keyframes status-in {
    from {
        height: 0;
    }

    to {
        height: auto;
    }
}