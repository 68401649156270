@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
// Replicate utils from shared-react-components

.ws-visually-hidden {
    @include ws-visually-hide;
}

.ws-hidden-phone {
    @include ws-media($ws-media-phone) {
        @include ws-visually-hide;
    }
}

.ws-hidden-tablet-desktop {
    @include ws-media($ws-media-tablet-and-desktop) {
        @include ws-visually-hide;
    }
}

.ws-hidden-desktop {
    @include ws-media($ws-media-desktop) {
        @include ws-visually-hide;
    }
}
