@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.diet-info-button {
    // Shame: Override MENY's default REOL styles
    color:  $ngr-meny-support04-dark-3;
    text-decoration: none;
    background-color: $ngr-meny-support01-light-1;
    border-radius: 100%;
    padding: 12px;
    width: 42px; // Den er avlang om jeg bare bruker padding
}
