@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-tips {
    @include ngr-font-size(kilo);

    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    border-radius: $app-border-radius;
    overflow: hidden;
    gap: 10px;

    &--background {
        &-white {
            background-color: $ngr-color-white;
        }

        &-gray {
            background-color: $ngr-meny-support02-light-1;
        }

        &-none {
            background-color: inherit;
        }

        &-light-red {
            background-color: $ngr-meny-support05-light-1;
        }

        &-light-green {
            background-color: $ngr-alert-color-background--success;
        }
    }

    &--padding {
        &-large {
            padding: 24px;
        }

        &-medium {
            padding: 16px;
        }

        &-small {
            padding: 10px;
        }

        &-none {
            padding: 0;
        }
    }

    &--large {
        grid-template-columns: 1fr;
        justify-items: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 8px;
    }

    &__title {
        @include ngr-font-size(india);
        font-weight: bold;

        .app-tips--large & {
            @include ngr-font-size(echo);
        }

        &--futura {
            font-family: $ngr-font-family--header;

            .app-tips--large & {
                @include ngr-font-size(delta);
            }
        }
    }

    &__text {
        @include ngr-font-size(kilo);

        .app-tips--large & {
            @include ngr-font-size(india);
            padding: 10px
        }
    }

    &__close-button {
        position: absolute;
        right: 3px;
        top: 3px;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        padding: 10px;

        .app-tips--large & {
            align-items: center;
        }
    }

    &__illustration {
        color: $ngr-meny-primary-base;

        .app-tips--small & {
            align-self: center;
            height: 100%;
        }
    }

    .call-to-action-button,
    .ngr-button--cancel {
        padding: 0;
    }
}