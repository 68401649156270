@import "@ng-mw/shared-meny-components/src/resources/styles/style-settings";

// ==========================================================================
// REOL Overrides
// ==========================================================================

$ngr-accordion-item-color-foreground: $ngr-color-foreground--primary;
$ngr-modal-padding: 32px;
$ngr-modal-padding--mobile: 24px;

// ==========================================================================
// Shared React Components Overrides
// ==========================================================================

$ws-red: $ngr-meny-primary-base;
$ws-secondary-color: $ngr-meny-primary-base;
$ws-light-gray: $ngr-meny-support01-light-1;

//Price variables overrides
/* stylelint-disable */
$ws-product__price-former-color: $ngr-meny-support02-dark-3;
$ws-product__price-former-text-decoration: line-through;
$ws-product__price-discounted-color: $ngr-meny-primary-base;
$ws-product__price-discounted-background: transparent;
$ws-product--vertical__price-discounted-padding: 0;
$ws-product--horizontal__price-discounted-padding: 0;

// Set header height to 0 so the TransactionHistory doesn't reserve extra space for it.
$ws-header-height-desktop: 0px;
$ws-header-height-tablet: 0px;
$ws-header-height-phone: 0px;
/* stylelint-enable */

$ws-use-bonus-checkmark-foreground: $ngr-trumf-color-support-1--darken-40;
$ws-use-bonus-checkmark-background: $ngr-trumf-color-support-1--lighten-60;

$ws-account-verification-banner-foreground: $ngr-color-foreground--primary;
$ws-account-verification-banner-background: $ws-warning-bg-color;

$ws-add-to-cart-radius: $ngr-border-radius;

// Insight
$ws-insight-color-alt-foreground: $ngr-color-foreground--primary--muted;

// SAVINGS

$ws-savings-color-fg-primary-trumf: #FFF;
$ws-savings-color-bg-primary-trumf: $ngr-meny-trumf-blue;
$ws-savings-color-fg-primary-tilbud: $ngr-meny-primary-light-1;
$ws-savings-color-bg-primary-tilbud: $ngr-meny-primary-base;
$ws-savings-color-fg-primary-kuponger: $ngr-meny-primary-light-1;
$ws-savings-color-bg-primary-kuponger: $ngr-meny-support02-dark-3;
$ws-savings-color-fg-primary-knallkjop: $ngr-meny-support02-dark-3;
$ws-savings-color-bg-primary-knallkjop: $ngr-meny-support02-light-2;

// ==========================================================================
// Global MENY App Variables/Mixins
// ==========================================================================

$app-medium-gray: $ngr-meny-support02-light-2;
$app-light-gray: $ngr-meny-support01-light-2;
$app-green: $ngr-meny-support04-dark-2;
$app-shadow-color: $ngr-meny-support03-dark-3;
$app-box-shadow: 0 1px 3px rgba($app-shadow-color, 0.1);
$app-faint-border-color: $ngr-meny-support01-light-2;
$app-border-radius: 8px;

$app-animation-duration: 0.4s;

$app-footer-height: 64px; // Height exluding 16px bottom padding
$app-footer-padding-bottom: 16px;

@mixin inspiration-paper() {
    background-color: #b5936e; // TODO replace paper image fallback color
    /* stylelint-disable */
    background-image: url("/images/paper_q80.jpeg");
    background-image:
        image-set(url("/images/paper_q80.webp") type("image/webp"),
            url("/images/paper_q80.jpeg") type("image/jpeg"));
    /* stylelint-enable */
}