@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.product-list-skeleton {
    margin: 0;
    padding: 8px;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__item {
        position: relative;
        display: flex;
        gap: 8px;
    }

    &__title {
        width: 160px;
        height: 22px;
    }

    &__subtitle {
        width: 190px;
        height: 18px;
    }

    &__price {
        width: 60px;
        height: 20px;
    }

    &__image {
        height: 56px;
        width: 56px;
    }

    &__wrapper {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 5px;
    }

    &__content {
        width: 100%;
    }

    &__quantity-content {
        height: 28px;
        width: 28px;
    }
}