@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-link {
    // no need to set any default style here as it's always overridden anyway
    // NOTE if any style set here will often override any global styles, since this will most likely lazy load after the global styles

    &--skin-button {
        padding: 24px;
        background-color: $ngr-meny-support02-light-1;
        border-radius: $app-border-radius;
        color: $ngr-color-foreground--primary;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex: 1;
        font-weight: 700;
    }

    &--skin-link {
        box-shadow: 0 -1px $ngr-meny-primary-base inset;
    }
}
