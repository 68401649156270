@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-navigation-item {
    transition: all .2s;
    flex: 1 1 19vw;
    color: $ws-white;
    height: 100%;

    &--active {
        font-weight: bold;
    }

    &:empty {
        display: none;
    }

    &__link {
        display: flex;
        padding: 0;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        padding-top: 16px;
        position: relative;
        height: 100%;
    }

    &__icon {
        .ngr-icon__svg {
            border-radius: 4px;
            transition: background-color $app-animation-duration linear;
            padding: 1px;
        }

        &--active {
            .ngr-icon__svg {
                background-color: $ws-white;
                color: $ws-secondary-color
            }
        }
    }

    &__text {
        font-size: min(.6875rem, 5vw); // 11px
        line-height: .7500rem; // 12px
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        position: absolute;
        bottom: 0;
    }

    &__icon-wrapper {
        position: relative;
    }

    &__badge {
        position: absolute;
        left: 50%;
        top: 35%;
        margin-left: -19px;
        background: $ngr-meny-black;
        color: $ngr-meny-white;
        border-radius: 50%;
        font-size: .625rem; // 10px
        min-width: 18px;
        line-height: 18px;
        height: 18px;
        text-align: center;
        font-weight: bold;
    }
}
