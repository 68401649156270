@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.become-loyalty-club-member-poster {
    border-radius: $app-border-radius;
    overflow: hidden;

    &__content {
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    &__title {
        @include ngr-font-size(delta);

        font-family: $ngr-font-family--header;
        text-align: center;
    }

    &__image {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    &__message {
        @include ngr-font-size(india);

        text-align: center;
        margin-bottom: 16px;
    }

    &__buttons {
        margin-top: 24px;
    }
}