@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-header {
    color: $ngr-color-foreground--primary;
    box-sizing: content-box;
    background-color: $ngr-meny-white;
    position: relative;
    transition: transform .5s ease-in-out;
    border-bottom: 1px solid $ngr-meny-white;
    padding-bottom: 1px;

    &--is-top {
        border-bottom: 1px solid $ngr-meny-white;
    }

    &--is-going-down {
        border-bottom: 1px solid $ngr-meny-support01-light-2;
    }


    &--is-going-down & {
        &__content {
            max-height: 0;
            opacity: 0;
        }
    }

    &--is-searching & {
        &__content {
            max-height: fit-content;
            opacity: 1;
        }

        &__grid {
            transform: translateY(-100%);
            opacity: 0;
            max-height: 0;
        }
    }

    &__content {
        transition: opacity ease-in .3s, max-height 3.4s ease-in-out;
    }


    &__inner {
        height: $ws-header-content-height;
        position: relative;

        >* {
            position: absolute !important;
            width: 100%;
            bottom: 0;
        }
    }

    &__grid {
        min-height: $ws-header-content-height;
        display: flex;
        align-items: center;
        background-color: $ngr-meny-white;
        position: relative;
        z-index: 2;
        padding: calc(16px + var(--safe-area-top)) $ws-container-horizontal-gutter $ws-container-horizontal-gutter;
        transition: all .3s;
        max-height: fit-content;


        &-left {
            margin-right: 0;
        }

        &-right {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            justify-content: flex-end;
        }

        &-center {
            overflow: hidden;
            flex: 1;
        }
    }

    &__back-button {
        font-size: 1.25rem;

        svg {
            height: 1.65em;
            width: 1.4em;
            display: block;
        }
    }

    &__title {
        @include ngr-font-size(golf);

        font-weight: 700;
        display: block;
        white-space: nowrap;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0;

        &--is-top {
            opacity: 0;
        }

        &--is-going-down {
            opacity: 1;
            transition: opacity ease-in .3s;
        }

        &--is-primary {
            @include ngr-font-size(delta);

            font-weight: 700;
            opacity: 1;
        }

        &--center {
            text-align: center;
        }
    }

    &__sub {
        background-color: $ngr-meny-white;
        padding: 0 $ws-container-horizontal-gutter $ws-container-horizontal-gutter;

        &-title {
            @include ngr-font-size(foxtrot);

            font-weight: 700;
            display: block;
            text-align: left;
        }

        .searchpage__focus & {
            padding: $ws-container-horizontal-gutter;
        }

        &--padding-none {
            padding: 0;
        }
    }

    &__search-button {
        opacity: 0;
        transition: opacity .5s ease-in-out;

        &--is-top {
            opacity: 0;
        }

        &--going-down {
            opacity: 1;
        }
    }

    &__filter {
        &__heading {
            @include ngr-font-size(mike);

            background-color: $ws-light-gray;
            padding: 14px 16px;
            position: relative;
            color: $ws-dark-gray;

            &__title {
                text-transform: uppercase;
                display: block;
                font-weight: bold;

                /* stylelint-disable-next-line max-nesting-depth */
                em {
                    opacity: .7;
                }
            }

            &__value {
                display: block;
                overflow: hidden;
                position: absolute;
                right: 16px;
                top: 16px;
            }
        }

        &__only-offers {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 16px;
        }

        &__sorting {
            &__element {
                position: relative;

                /* stylelint-disable-next-line max-nesting-depth */
                label {
                    display: block;
                    padding: 16px;
                }

                /* stylelint-disable-next-line max-nesting-depth */
                &--active {
                    font-weight: bold;
                    border: $ngr-input-border;
                    border-radius: 3px;
                }

                /* stylelint-disable-next-line max-nesting-depth */
                .ngr-icon {
                    width: 20px;
                    position: absolute;
                    top: 50%;
                    right: 16px;
                    transform: translateY(-50%);
                    color: $ws-secondary-color;
                }
            }
        }
    }
}

.keyboard-nav-skip-link {
    position: absolute;
    top: -5000px;
    left: -5000px;
    background-color: $ngr-meny-white;
    color: $ngr-meny-pure-black;

    &:focus-visible {
        top: 0;
        left: 0;
    }
}