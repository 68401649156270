@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.checkout-payment-popup {
    &--ios {
        height: 100%;
        width: 100%;
        min-width: 100%;
    }

    &--android {
        display: block;
        height: 100%;
        width: 100%;
        min-height: unset;
        min-width: 100vw;
        /* stylelint-disable */
        *width: 100vw;
        /* stylelint-enable */
    }

    &__box {
        &--ios {
            height: 300px;
            width: 100%;
            position: relative;
        }

        &--android {
            overflow-y: hidden;
            position: relative;
        }
    }

    &__wrapper {
        position: absolute;
        height: 100%;
        left: 0;
        right: 0;
        padding: 0 16px; // the terminal does not have padding inside IFrame

        &--ios {
            min-width: 50%;
            min-height: 50%;
            max-height: 500%;
            top: 0;
            -webkit-overflow-scrolling: touch !important;
            overflow-y: scroll !important;
        }
    }
}
