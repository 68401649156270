@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";

.tmp-campaign-popup {
    &__image {
        width: 100%;
        height: 25vh;
        object-fit: cover;
        margin-bottom: 32px;
    }

    &__textualIcon svg {
        // margin: 0 auto 10px;
        vertical-align: -30%;
        margin-inline-end: 0.3em;
    }

    &__login-button {
        width: 100%;
    }

    &__login-content {
        margin-top: 32px;
    }

    .app-popup-title__text {
        @include ngr-font-size(foxtrot);
    }

    .app-popup-title__text.tmp-campaign-popup__subtitle {
        @include ngr-font-size(golf);
    }

    p {
        margin-bottom: 24px;
    }
}
