@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-shopping-list-link {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 16px;
    background-color: $ngr-meny-support02-light-1;
    border-radius: $app-border-radius;

    &__name {
        @include ngr-font-size(juliette);

        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100px;
        transition: color .3s linear;
    }

    &__quantity {
        @include ngr-font-size(kilo);

        color: $ws-text-muted-color;
        margin-top: 5px;
    }

    &__product-images {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        flex: 1;
    }

    &__product-image {
        width: 40px;
        border-radius: $app-border-radius;
    }
}

