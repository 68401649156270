@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-popup-title {
    &__illustration {
        text-align: center;

        img {
            margin: 0 auto 16px;
            max-height: 40px;
            width: auto;
        }
    }

    &__text {
        @include ngr-font-size(echo);

        font-weight: bold;
        padding: 0 16px;
        margin-bottom: 24px;

        &--simple {
            text-align: center;
        }
    }
}