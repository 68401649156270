@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.editorial-content {
    margin-bottom: 34px;

    &__item {
        border-radius: $app-border-radius;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        height: 220px;
        background-color: $ngr-meny-support02-light-1;

        &--style-text_image {
            background-color: $ngr-meny-black;
            color: $ngr-color-white;
        }

        &--style-inspiration {
            background-color: $ngr-meny-support02-light-1;
        }
    }


    &--loading {
        margin: 0 1rem;
    }

    &__content {
        padding: 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 105px;
        gap: 8px;
        overflow: hidden;
    }

    &__link {
        margin-top: auto;

        .ngr-icon {
            color: $ngr-meny-primary-base;
        }
    }

    &__title {
        @include ngr-font-size(foxtrot);
        display: block;
        font-family: $ngr-font-family--header;
        font-style: normal;
        font-weight: 700;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;
        overflow: hidden;
    }

    &__image {
        width: auto;
        height: 115px;
        object-fit: cover;
    }
}