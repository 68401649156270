@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    backface-visibility: hidden;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: flex-end;

    &.slide-left {
        &-enter {
            transform: translateX(100vw);

            &-active {
                // see https://github.com/reactjs/react-transition-group/issues/10
                transition: transform $app-animation-duration ease-out;
                transform: translateX(0);
            }
        }

        &-exit {
            transform: translateX(0);

            &-active {
                // see https://github.com/reactjs/react-transition-group/issues/10
                transition: transform $app-animation-duration ease-out;
                transform: translateX(-100vw);
            }
        }
    }

    &.slide-up {
        &-enter {
            transform: translateY(100vh);

            &-active {
                // see https://github.com/reactjs/react-transition-group/issues/10
                transition: transform $app-animation-duration ease-out;
                transform: translateY(0);
            }
        }

        &-exit {
            transform: translateY(0);

            &-active {
                // see https://github.com/reactjs/react-transition-group/issues/10
                transition: transform $app-animation-duration ease-out;
                transform: translateY(100vh);
            }
        }
    }

    &__background {
        background-color: rgba($ws-dark-bg-color, .5);
        position: fixed;
        overflow: hidden;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: 1;

        &.fade {
            &-enter {
                opacity: 0;

                /* stylelint-disable-next-line max-nesting-depth */
                &-active {
                    transition: opacity $app-animation-duration linear;
                    opacity: 1;
                }
            }

            &-exit {
                opacity: 1;

                /* stylelint-disable-next-line max-nesting-depth */
                &-active {
                    transition: opacity $app-animation-duration linear;
                    opacity: 0;
                }
            }
        }
    }
}