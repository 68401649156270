@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.trumf-link {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: $ngr-meny-support02-light-1;
    border-radius: $app-border-radius;

    &__text {
        flex: 1;
        padding-left: $ws-container-horizontal-gutter;
    }

    &__trumf-balance {
        @include ngr-font-size(juliette);
    }

    &__trumf-text {
        @include ngr-font-size(kilo);
    }
}