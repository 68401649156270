@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-home {
    &__handover-picker {
        margin-bottom: 34px;
    }

    &__order-status {
        border: 0;

        &--loading {
            min-height: 384px;
        }
    }

    &__products-list {
        min-height: 292px;
    }

    //shame
    .ws-order-status {
        margin: 0;
    }

    .savings-summary {
        .ngr-card__content {
            border: 0;
        }

        &__illustration {
            position: absolute;
            right: -27px;
            color: $ngr-meny-primary-base;
            top: 0;
        }

        &__card {
            border: 0;
            text-align: left;
            overflow: hidden;
        }
    }
}