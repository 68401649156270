@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
// Component overrides

.ws-registration__progress-indicator-wrapper {
    margin-top: 24px;
}



.ws-handover-picker-content--handover-type {
    margin-top: 16px;
}

.ws-presubmit-status {
    padding: 12px 16px;
}

// temporary fix for scroll bug. Will be fixed in next version of REOL
.ws-profile-block--transparent {
    box-shadow: none !important;
}

// Some quick hacks:

// Extra padding to allow scrolling on android
.android-keyboard-padding {
    padding-bottom: 41vh;
}

.ws-transaction-history-table__details-card--details-active {
    top: 0;
    bottom: 0;
    height: auto;
    position: absolute;
}

.ngr-modal .ngr-modal__box {
    &--medium,
    &--large {
        top: 39px; // Safe-area for iPhone with notch (47px) minus 8px padding
    }

    .ngr-modal__content {
        max-height: calc(var(--vh, 1vh) * 100 - 119px); // Default padding from REOL (80px) plus 39px
    }
}

// Fixes for handover info tooltip
.ws-handover-time-status {
    position: relative;

    .ngr-button--inline-link,
    .ngr-button--inline-block-link,
    .ngr-button--plain-link {
        color: $ngr-meny-white;
    }

    .ws-tooltip {
        background-color: $ngr-meny-white;
        color: $ngr-meny-pure-black;
        top: 50px !important;
        left: 16px !important;
        right: auto !important;

        &--place-bottom::after {
            border-bottom-color: $ngr-meny-white;
        }

        &--place-right::after {
            border-right-color: $ngr-meny-white;
        }

        &--place-left::after {
            border-left-color: $ngr-meny-white;
        }

        // Hacks to fix the position:
        &__wrapper {
            position: static !important;
        }

        &--place-bottom::after,
        &--place-right::after,
        &--place-left::after {
            border-color: transparent !important;
        }
    }
}

.ws-popup__header-button .ngr-icon {
    width: 13px;
    height: 13px;
}

.ws-dialog__box,
.ws-dialog__box--medium,
.ws-dialog__box--large {
    margin: 60px 8px;

    .ws-dialog__description {
        max-height: calc(100vh - 200px);
    }
}

.ws-deadline-reminder .ws-link {
    text-decoration: underline;
    color: $ngr-meny-primary-base;
}

.ws-product-details {
    @include ngr-media("(max-width: 400px)") {
        &__header {
            margin-top: 2.5rem;
            margin-right: 0;
        }
    }

    &__header {
        margin-top: .5rem;
        margin-right: 2.5rem;
    }
}

.ws-product-list-vertical {
    background: $ngr-meny-white;
}

.ngr-accordion-item__indicator {
    color: $ngr-meny-primary-base;
}

.consent-act__title {
    text-align: left;
}

.ws-category-filter__antishift-container {
    box-sizing: border-box;
    padding: 32px 0 0 16px;
}

.ws-pre-submit-status__order-edit-status,
.ws-order-edit-status {
    background-color: $ngr-meny-medium-gray !important;
    color: $ngr-color-foreground--primary !important;
}

// TODO move fix to FK
.ws-product__text-content {
    overflow: visible !important;
}

.ws-image {
    opacity: 0;
    position: relative;
    transition: opacity .35s;
    width: 100%;
    height: 100%;

    &--loaded {
        opacity: 1;
    }

    &--simple {
        transition: none;
        opacity: 1;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.ws-product-view-filter {
    margin: 0;
    padding: 16px;

    .ws-offers-filter {
        margin-right: 0;
    }
}

.ws-product-view__empty-result {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: calc(45vh - 160px);
    opacity: .65;
}

@media (width >=520px) {
    .ws-handover-time-status,
    .ws-order-deadline-status,
    .ws-order-edit-status {
        width: 100%;
    }
}