@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.first-trumf-id {
    text-align: center;

    &__iconbox {
        margin: 0 0 2rem;
    }

    &__flash {
        color: $ngr-meny-primary-base;
        transform: translate(-58px) rotate(-41deg);
        display: block;
    }

    .ws-login-button {
        width: 100%;
    }
}
