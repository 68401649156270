@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.login-popup {
    &__button {
        width: 100%;
    }

    &__image {
        width: 100%;
        display: block;
        height: auto;
        object-fit: cover;
        margin-bottom: 32px;
    }
}