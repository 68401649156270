@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-shopping-lists {
    &--loading {
        height: 100%;
    }

    &__intro {
        @include ngr-font-size(india);

        padding: 1rem;
    }

    &__footer {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: 1rem;
    }

    &__illustration {
        color: $ngr-meny-primary-base;
    }

    // Shame

    .ws-shopping-lists__wrapper {
        margin-top: 1rem;
    }

    .ws-shopping-lists {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .ws-shopping-list-link-item {
        box-shadow: none;
        border-radius: 6px;
    }
}

.shopping-lists-home {
    &__create-button {
        width: 100%;
    }

    &--loading {
        height: 100%;
    }

    .app-shopping-lists {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 2rem;
    }

    &__create-button {
        width: 100%;
    }
}

// Shame

.create-shopping-list-page .ws-create-shopping-list {
    display: flex;
    justify-content: left;
    padding: 1rem 1rem 0;
    position: relative;
    flex-wrap: wrap;
}