@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.user-consents {
    &__section-title {
        @include ngr-font-size(golf);
        font-weight: bold;
        margin: 0 0 16px;
    }

    &__section-subtitle {
        @include ngr-font-size(india);
        font-weight: bold;
        margin: 0 0 16px;
    }

    &__os-settings {
        display: flex;
        gap: 24px;
        padding: 8px 0 24px;
        border-bottom: 1px solid $ngr-color-gray-4;
        margin-bottom: 24px;
    }

    &__os-settings-label {
        @include ngr-font-size(kilo);
        flex: 1;
    }

    &__os-settings-link {
        text-decoration: underline;
        color: $ws-red;
    }

    //&__os-settings-value { }
}
