@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-version-status {
    position: absolute;
    overflow: hidden;
    bottom: 100%;
    left: 0;
    right: 0;
    animation: status-in $ws-transition-duration both;
    z-index: 10;

    &__box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 14px;
        margin: 10px;
        box-shadow: 0 6px 11px rgba(23, 20, 18, 0.2);
        border-radius: $ngr-border-radius;
        background-color: $ngr-meny-white;
    }

    &__icon {
        color: $ngr-meny-primary-base;
    }

    &__info {
        display: inline-flex;
        gap: 10px;
        align-items: center;
    }
}

@keyframes status-in {
    from {
        height: 0;
    }

    to {
        height: auto;
    }
}