@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-popup-text {
    @include ngr-font-size(india);

    p:not(:last-child) {
        margin-bottom: 11px;
    }

    ul {
        margin: 1rem 0;

        li {
            margin: 0 0 1rem 1rem;
            list-style-position: outside;
            list-style-type: disc;
        }
    }

    ol {
        margin: 1rem 0;

        li {
            margin: 0 0 1rem 1rem;
            list-style-position: outside;
            list-style-type: decimals;
        }
    }
}
