@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
// TODO replace '../fonts' below with some url to a
// CDN or a common server where every NG-app and website stores their shared fonts.
// I've tested using trumf-react-preprod.azurewebsites.net and trumf.no but it doesn't work
// from localhost because of missing CORS.

@font-face {
    font-family: Beatrice;
    font-weight: normal;
    font-style: normal;
    src:
        url("../fonts/Beatrice-Regular.woff2") format("woff2"),
        url("../fonts/Beatrice-Regular.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: Beatrice;
    font-weight: bold;
    font-style: normal;
    src:
        url("../fonts/Beatrice-Semibold.woff2") format("woff2"),
        url("../fonts/Beatrice-Semibold.woff") format("woff");
    font-display: swap;
}

// Shame

.ws-trumf-qr-code {
    font-family: Beatrice, Arial, Helvetica, sans-serif;
}
