@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.app-loading-skeleton {
    margin: 0;

    &--is-loading {
        background-color: $ngr-meny-support02-light-2;
        border-radius: $app-border-radius;
        position: relative;
        display: block;
        animation: pulsate 1s infinite;
    }

    &__children {
        opacity: 1;

        &--is-loading {
            opacity: 0;
        }
    }

    &--margin-small {
        margin: 8px;
    }

    &--margin-medium {
        margin: 16px;
    }

    &--margin-large {
        margin: 24px;
    }
}

@keyframes pulsate {
    0% {
        opacity: .8;
    }

    50% {
        opacity: .4;
    }

    100% {
        opacity: .8;
    }
}