@import "./node_modules/@ng-mw/reol/core-menyapp.scss";@import "./node_modules/@ng-mw/shared-react-components/style-settings-default.scss";@import "./src/resources/style/_variables.scss";
.campaign-item {
    border-radius: $app-border-radius;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: $ws-secondary-color;
    color: $ngr-meny-white;
    height: 100%;
    width: 281px;

    &--loading {
        margin: 0 1rem;
    }

    &__image {
        width: auto;
        height: 169px;
        object-fit: cover;
    }

    &__heart {
        color: $ngr-meny-primary-dark-1;
        position: absolute;

        &--left {
            transform: rotate(40deg);
            margin-left: -110px;
            margin-top: 45px;
        }

        &--right {
            transform: rotate(-44deg);
            margin-left: 138px;
        }
    }

    &__container {
        z-index: 1;
        display: grid;
        grid-template-rows: 0.5fr 2.5fr 1fr;
    }

    &__content {
        padding: 16px;
        display: flex;
        flex: 1;

        &--loyalty-club {
            padding-top: 9px;
        }
    }

    // Shame

    .ws-link {
        display: block;
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 2;
    }

    &__tag {
        @include ngr-font-size(kilo);
        font-weight: 700;
        height: 32px;
    }

    &__title {
        @include ngr-font-size(foxtrot);

        font-family: $ngr-font-family--header;
        line-height: 32px;
        font-style: normal;
        font-weight: 700;
        display: block;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-word;
        overflow: hidden;
        max-height: 96px;
    }

    &__icon-container {
        width: 38px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $ngr-meny-primary-dark-1;

        &--loyalty-club {
            background-color: $ws-secondary-color;
        }
    }
}